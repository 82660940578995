<template>
    <div>
        <a-card>
            <a-row style="margin-bottom: 5px;">
                <a-col :span="24" style="text-align: right;">
                    <a-checkbox @change="onChange" style="margin-right: 10px;">
                        {{ l('Showonlythefollowings') }}
                    </a-checkbox>

                    <a-select showSearch :filterOption="filterOption"
                        style="width: 150px;text-align: left;margin-right: 10px;" dropdownClassName="drop-down-Style"
                        :dropdownMatchSelectWidth="false" :placeholder="l('Please_select_the_person_responsible')"
                        allow-clear @change="LPAUserChange">
                        <a-select-option v-for="item in AllLPAUser" :key="item.id" :title="item.realName">
                            {{ item.realName }}
                        </a-select-option>
                    </a-select>

                    <a-select allowClear :placeholder="l('issueclassify')"
                        style="width: 120px;margin-right: 10px;margin-bottom: 10px;" @change="statusChange">
                        <a-select-option v-for="item in typeCodeList" :key="item.itemDetailCode">
                            {{ item.itemDetailName }}
                        </a-select-option>
                    </a-select>
                    <a-tree-select style="width: 200px;margin-right: 10px;" :tree-data="tree" :replace-fields="treeRep"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto', width: '300px' }"
                        :placeholder="l('issuetype')" @change="treeRepChange" allow-clear tree-default-expand-all>
                    </a-tree-select>
                    <!-- <a-select showSearch :filterOption="filterOption"
                              style="width: 100px;text-align: left;margin-right: 10px;"
                              dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false"
                              :placeholder="l('issueprogress.Progress')"
                              @change="AuditTaskStateChange"
                              allow-clear>
                        <a-select-option v-for="item in AuditTaskStateList" :key="item.progress"
                                         :title="item.itemDetailName">
                            {{ item.progress }}
                        </a-select-option>
                    </a-select>-->
                    <a-tree-select style="width: 200px;margin-right:10px;" :tree-data="treeData"
                        :replace-fields="treeRepData"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto', width: '300px' }"
                        :placeholder="l('220')" @change="treeRepChange1" allow-clear tree-default-expand-all>
                    </a-tree-select>
                    <a-range-picker @change="timeChange" :placeholder="[l('audit.planstart'), l('audit.planend')]"
                        style=" width: 250px;text-align: center;" />
                    <a-input-search :placeholder="l('Please_enter_the_problem_description_to_search')"
                        v-model.trim="filterText" enterButton style="width: 200px;margin-left: 10px;"
                        @search="filteronchange"></a-input-search>
                </a-col>
            </a-row>
            <div style="overflow-x:auto;max-width:100%;" :style="styObj">
                <a-spin :spinning="isLoading" style="top: -10px;">
                    <div style="max-width:100%;display: flex;flex-direction: row;margin-top: 10px">
                        <div class="headtitel" v-for="(item, index) in issuelist" style="font-weight:bold">
                            <div class="yuan" :style="{ backgroundColor: item.backColor }" :id="index"></div>
                            <font :style="{ color: item.foreColor }">
                                {{ item.progressName }}
                            </font>
                            <div style="margin-left: auto;margin-right: 20px;font-weight:normal"><a-icon type="file"
                                    style="margin-right:5px" />{{ item.count }}</div>
                        </div>
                    </div>

                    <div style="max-width:100%;display: flex;flex-direction: row;">
                        <div class="issue" v-for="(issue, index) in issuelist"
                            @scroll="handleScroll($event, issue, index)" :key="index" :style="styObj1">
                            <div v-for="(dto, dtoIndex) in issue.issueListDto" :key="dtoIndex">
                                <a-card style="width: 370px;margin-top: 5px;margin-bottom: 5px" @click="edit(dto)">
                                    <div class="cardrow">
                                        <div style="font-weight: bold;font-size: 18px;width: 95%;">{{ dto.description }}
                                        </div>
                                        <a @click.stop="concern(dto)">
                                            <a-icon type="star" style="color: #f4ca3a;font-size: 25px;"
                                                :theme="dto.issueSubscribeId ? 'filled' : 'outlined'"
                                                :title="dto.issueSubscribeId ? '关注' : '未关注'" />
                                        </a>
                                    </div>
                                    <div class="cardrow">
                                        <div>{{ l('auditpoint') }}：{{ dto.auditPoint }}</div>
                                    </div>
                                    <div class="cardrow">
                                        <div>{{ l('issuetype') }}：{{ dto.issueTypeListName }}</div>
                                    </div>
                                    <div class="cardrow">
                                        <div class="cardrow">{{ l('issue.discoverypicture') }}：
                                            <div style="min-height: 30px;" class="images" v-vuer="{ title: false }"
                                                @click.stop="edit1()">
                                                <img :src="pic" style="margin-left: 5px;width: 30px;height: 30px"
                                                    :key="pic + 'div'" v-for="(pic, index) in dto.piclits">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cardrow">
                                        <div v-if="issue.progress === 0">
                                            {{ l('Submissiontime') }}：<span>{{ dto.discoveryTime }}</span></div>
                                        <div v-if="dto.newclass && issue.progress != 100 && issue.progress != 0">
                                            {{ l('closing_date') }}：<span style="color: red">{{ dto.planEnd }}</span></div>
                                        <div
                                            v-if="(dto.newclass == false && issue.progress != 0) || issue.progress == 100">
                                            {{ l('closing_date') }}：<span>{{ dto.planEnd }}</span></div>
                                        <div style="margin-left: auto;margin-right: 3px">{{ dto.respName }}</div>
                                    </div>
                                </a-card>
                            </div>
                            <div v-if="issue.loading" class="isloading">{{ l('Loading_more') }}</div>
                            <div v-if="issue.loading == false && issue.issueListDto.length > 0" class="isloading">
                                {{ l('all_data') }}</div>
                            <div v-if="issue.issueListDto.length == 0" class="isloading" style="margin-top: 20px">
                                {{ l('no_data') }}</div>
                        </div>

                    </div>

                </a-spin>
            </div>
        </a-card>
    </div>
</template>

<script>
import {
    AppComponentBase
} from "@/shared/component-base";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import {
    IssueServiceProxy,
    IssueProgressServiceProxy, AuditPointServiceProxy, IssueTypeServiceProxy,
    AuditTaskServiceProxy
} from '../../../shared/service-proxies';
import {
    ModalHelper
} from '@/shared/helpers';
import infiniteScroll from 'vue-infinite-scroll';
import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import moment from 'moment';
import issueview from "../problem-management/view/view";
import { Dic } from "../../../shared/utils";
import 'viewerjs/dist/viewer.css'
import Vuer from 'v-viewer'
import Vue from 'vue'
import { AppConsts } from "../../../abpPro/AppConsts";
import Viewer from 'viewerjs/dist/viewer.esm';
Vue.use(Vuer, { name: 'vuer' })

let _this;
export default {
    name: "issueKanban",
    mixins: [AppComponentBase],
    components: {
        issueview
    },
    created() {
        _this = this;
        this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
        this.IssueProgressServiceProxy = new IssueProgressServiceProxy(this.$apiUrl, this.$api);
        this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
        this.IssueTypeServiceProxy = new IssueTypeServiceProxy(this.$apiUrl, this.$api);
        this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
    },
    async mounted() {
        this.typeCodeList = await Dic.getInstance().getDicAsync("SourceType");
        this.getissueProgress();
        this.getTreeData2();
        this.getTreeData();
        this.getAllLPAUser();
        this.getData();

        window.addEventListener('resize', this.changeHeight);
        this.changeHeight();
    },
    data() {
        return {
            nowtime: new Date(),
            isLoading: false,//加载中
            loading: false,
            busy: false,
            typeCodeList: [],
            issueprogress: [],
            issuelist: [],
            tree: [],
            treeRep: {
                title: 'name',
                key: 'id',
                value: 'id'
            },
            treeData: [],
            treeRepData: {
                title: 'name',
                key: 'id',
                value: 'id'
            },
            sourceName: "即时问题",
            Task: {
                name: undefined,
                progress: "未关闭",
                backColor: undefined,
                foreColor: undefined,
                id: undefined,
            },
            List: "%",
            workProcessClass: undefined,
            AuditTaskStateList: undefined,
            treeRepClass: undefined,
            treeRepDataClass: undefined,
            status: undefined,
            show1: false,
            Progress: undefined,
            filterText: undefined,
            uploadPictureUrl: AppConsts.OSSRequestUrl,
            styObj: {
                "max-height": 0,
                "min-height": 0,
            },
            styObj1: {
                "max-height": 0,
                "min-height": 0,
            },
            isStar: false,
            UserId: undefined,
            AllLPAUser: [],
        }
    },

    // watch: {
    //     issuelist: function () {
    //         this.$nextTick(function () {
    //             this.getcolor();
    //         })
    //     },
    // },

    // destroyed() {
    //     window.removeEventListener('scroll', this.handleScroll);
    // },

    methods: {
        //div高度
        changeHeight() {
            this.styObj["max-height"] = window.innerHeight - 230 + 'px';
            this.styObj["min-height"] = window.innerHeight - 230 + 'px';
            this.styObj1["max-height"] = window.innerHeight - 300 + 'px';
            this.styObj1["min-height"] = window.innerHeight - 300 + 'px';
        },

        LPAUserChange(value) {
            console.log(value);
            this.UserId = value;
            this.getData();
        },

        getAllLPAUser() {
            this.isLoading = true;
            this.AuditTaskServiceProxy.getAllLPAUsers(
            )
                .finally(() => {
                    this.isLoading = false;
                })
                .then((res) => {
                    console.log(res);
                    this.AllLPAUser = res;
                })
        },

        getData() {
            this.isLoading = true;
            this.IssueServiceProxy.getIssuepage(
                this.status,
                this.treeRepDataClass,
                this.treeRepClass,
                this.workProcessClass,
                this.startdate,
                this.enddate,
                this.UserId,
                this.filterText ? this.filterText : undefined,
                undefined,
                this.isStar,
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                // console.log(res, 'alldata');
                this.issuelist = [];
                console.log(this.issuelist);
                res.forEach(item => {
                    item.loading = false;
                    item.skip = 0;
                    item.issueListDto.forEach(item2 => {
                        item2.piclits = [];
                        item2.ispic = false;
                        if (item2.discoveryPicture != "" || item2.discoveryPicture != undefined) {
                            var pic = item2.discoveryPicture.split(',');
                            pic.forEach(g => {
                                if (g != "") {
                                    var path = item2.realPath + '/' + g;
                                    item2.piclits.push(path);
                                }
                            });
                        }
                        // console.log(item2.planEnd,'1')
                        if (item2.planEnd <= this.nowtime) {
                            item2.newclass = true;
                        } else {
                            item2.newclass = false;
                        }
                        if (item2.planEnd) {
                            item2.planEnd = moment(item2.planEnd).format('YYYY-MM-DD');
                        }
                        item2.discoveryTime = moment(item2.discoveryTime).format('YYYY-MM-DD HH:mm');
                    })
                });
                this.issuelist = res;
                console.log(this.issuelist);
                // console.log(this.issuelist,'88888888');
            })
        },


        view() {
            const vuer = this.$el.querySelector('.images').$vuer
            vuer.view()
        },

        //关注
        concern(dto) {
            // this.isLoading = true;
            let issueId = { IssueId: dto.id };
            if (dto.issueSubscribeId) {
                this.IssueServiceProxy.removeStar(
                    dto.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    dto.issueSubscribeId = ''
                })
            } else {
                this.IssueServiceProxy.addStar(
                    issueId
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    dto.issueSubscribeId = 'filled'
                })
            }
            // this.getData();
        },

        onChange(e) {
            console.log(e.target.checked);
            this.isStar = e.target.checked;
            this.getData();
        },

        // getcolor() {
        //     for (var i = 0; i < this.issuelist.length; i++) {
        //         var index = i.toString();
        //         document.getElementById(index).style.backgroundColor = this.issuelist[i].foreColor;
        //     }
        // },
        handleScroll(e, issue, index) {
            console.log(index, 'index');
            if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
                if (issue.issueListDto.length < issue.count) {
                    issue.loading = true;
                    issue.skip = issue.skip + 10;
                    this.IssueServiceProxy.getIssuepage(
                        this.status,
                        this.treeRepDataClass,
                        this.treeRepClass,
                        this.workProcessClass,
                        this.startdate,
                        this.enddate,
                        this.UserId,
                        this.filterText ? this.filterText : undefined,
                        issue.skip
                    ).finally(() => {
                        issue.loading = false;
                    }).then(entity => {
                        console.log(entity, 'entity');
                        entity.forEach(item => {
                            item.issueListDto.forEach(item2 => {
                                item2.piclits = [];
                                if (item2.discoveryPicture != "" || item2.discoveryPicture != undefined) {
                                    var pic = item2.discoveryPicture.split(',');
                                    pic.forEach(g => {
                                        if (g != "") {
                                            var path = item2.realPath + '/' + g;
                                            item2.piclits.push(path);
                                        }
                                    });
                                }
                                if (item2.planEnd <= this.nowtime) {
                                    item2.newclass = true;
                                } else {
                                    item2.newclass = false;
                                }
                                if (item2.planEnd) {
                                    item2.planEnd = moment(item2.planEnd).format('YYYY-MM-DD');
                                }
                                // item2.planEnd=moment(item.planEnd).format('YYYY-MM-DD');
                                item2.discoveryTime = moment(item2.discoveryTime).format('YYYY-MM-DD HH:mm');
                            });
                            if (item.progressName == issue.progressName) {
                                issue.issueListDto = issue.issueListDto.concat(item.issueListDto);
                            }
                        });
                    });
                } else {

                }

            }
        },


        //查看
        edit(record) {
            ModalHelper.create(issueview, {
                id: record.id,
                isKanban: true
            }, { width: 1000 }).subscribe(res => {
                if (res.issueSubscribeId != record.issueSubscribeId) {
                    this.getData();
                }
            })
        },

        edit1() {

        },

        //筛选条件
        filteronchange() {
            this.getData();
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        AuditTaskStateChange(value) {
            console.log(value);
            if (value != undefined) {
                value = value.replace("%", "");
            } else {

            }
            if (value == "未关闭") {
                this.workProcessClass = -1;
            } else {
                this.workProcessClass = value;
            }
            console.log(this.workProcessClass);
            this.getData();
        },
        treeRepChange(key) {
            console.log(key);
            this.treeRepClass = key;
            this.getData();
        },
        statusChange(value) {
            console.log(value); // { key: "lucy", label: "Lucy (101)" }
            this.status = value;
            this.getData();
        },
        timeChange(date, dateString) {
            this.startdate = date[0];
            this.enddate = date[1];
            this.getData();
        },
        treeRepChange1(key) {
            this.treeRepDataClass = key;
            this.getData();
        },
        //获取进度值
        getissueProgress() {
            this.isLoading = true;
            this.IssueProgressServiceProxy.getPaged(
            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                console.log(res);
                this.AuditTaskStateList = res.items;
                this.AuditTaskStateList.map(item => {
                    item.progress = item.progress + '%';
                    console.log(item.progress);
                })
                this.AuditTaskStateList.push(this.Task);
                console.log(this.AuditTaskStateList);
            })
        },
        //获取问题分类
        getTreeData2() {
            this.isLoading = true;
            this.IssueTypeServiceProxy.getPaged(

            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                console.log(res)
                this.tree = JSON.parse(JSON.stringify(res));
            })
        },
        //获取审核点
        getTreeData() {
            this.isLoading = true;
            this.AuditPointServiceProxy.getAllData(

            ).finally(() => {
                this.isLoading = false;
            }).then(res => {
                console.log(res)
                this.treeData = JSON.parse(JSON.stringify(res));
            })
        },


    }


}
</script>

<style scoped>
.yuan {
    width: 17px;
    height: 17px;
    /*padding-bottom: 10%;*/
    z-index: 20;
    border-radius: 50%;
    background-color: #ffffff;
    position: relative;
    align-items: center;
    margin-right: 3px;
    justify-content: center;
}

.headtitel {
    min-width: 400px;
    border: solid 1px #dcdcdc;
    border-radius: 5px;
    line-height: 30px;
    margin-left: 10px;
    padding-left: 5px;
    background-color: rgba(254, 255, 0, 0.04);
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center
}

.issue {
    min-width: 400px;
    margin-left: 10px;
    border: solid 1px #dcdcdc;
    border-radius: 5px;
    padding-left: 5px;
    background-color: rgb(245, 246, 248);
    margin-top: 5px;
    overflow-y: scroll;
}

.cardrow {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.isloading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #90a6c6
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(240, 240, 240, 0.5)
}
</style>
